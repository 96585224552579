import { getGetters } from '@/store/getters'
import { getMutations } from '@/store/mutations'
import { User as model } from '@/models/api_v1'
import { CmsAuthService as service } from '@/services/api_v1/cms'
import { toast } from '@/helpers/error.helper'
import _ from "lodash"

// Init
const getInitialState = () => ({
  user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
  itemInit: model.initialState(),
});

// Import
const { getInit } = getGetters()
const { setItemInitField, setInitialState, setItemInit } = getMutations({ model })

const getters = {
  getInit,

  getUser: (state) => {
    return model.fromApi(state.user)
  },
}
const mutations = {
  setItemInitField,
  setInitialState,
  setItemInit,

  setUser(state, value) {
    state.user = _.cloneDeep(value)
  },
  setUserField(state, { field, value }) {
    _.set(state.user, field, value)
  },
  resetUser(state) {
    localStorage.removeItem('user')
    state.user = null
  },
}
const actions = {

  //** 1.3.0 DEPRICATED */
  // async login({ state, commit, dispatch }, { payload }) {
  //   let output = null

  //   try {
  //     output = await service.login(payload)
  //   } catch (error) {
  //     toast({ msg: error })
  //     output = error
  //   } finally {
  //     return output
  //   }
  // },

  // async twoFA({ state, commit, dispatch }, { payload }) {
  //   let output = null

  //   try {
  //     output = await service.twoFA(payload)
  //   } catch (error) {
  //     toast({ msg: error })
  //     output = error
  //   } finally {
  //     return output
  //   }
  // },
  //** 1.3.0 DEPRICATED */

  async forgotPassword({ state, commit, dispatch }, { payload }) {
    let output = null
    output = await service.forgotPassword(payload)
    return output
  },
  async newPassword({ state, commit, dispatch }, { payload }) {
    let output = null
    output = await service.newPassword(payload)
    return output
  },

  async login({ state, commit, dispatch }, { payload }) {
    let response
    if(payload.code){
      response = await service.code(payload)
    } else {
      response = await service.login(payload)
      if(response.data.success){
        return 'code'
      }
    }
    if(response.data.access_token){
      localStorage.setItem('access_token', response.data.access_token)
      const user = await service.profile()
      localStorage.setItem('user', JSON.stringify(user.data))
      commit('setUser', user.data)
      return true
    }
    return response
  },

  async resend({ state, commit, dispatch }, { payload }) {
    return await service.resend(payload)
  },

  async logout({ state, commit, dispatch }) {
    return await service.logout()
  },

}

// Export
export default {
  namespaced: true,
  state: getInitialState(),
  getters,
  mutations,
  actions,
}
