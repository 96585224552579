const apiUrl = '/api/v1'
const resource = 'patient/agreement'

const update = (payload) => {
  return $axios.put(`${apiUrl}/${resource}`, payload)
}

const consentForm = () => {
  return $axios.get(`${apiUrl}/patient/report/consent_form.pdf`, {
      responseType: 'blob',
      transformResponse: [function (data) {
          let blob = new window.Blob([data], { type: 'application/pdf' })
          return window.URL.createObjectURL(blob)
      }]
  })
}

export default {
  update,
  consentForm,
}
