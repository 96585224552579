/**
 * @class RequestsFilterPayload
 * @classdesc
 */
export default class RequestsFilterPayload {
  /**
   * @param {object} props -
   * @param {string | null} props.search -
   * @param {array | null} props.test_item_ids -
   * @param {array | null} props.statuses - 'new_request', 'symptoms_added', 'conclusion_added', 'report_completed', 'test_completed'
   * @param {number | null} props.page -
   */
  constructor(props) {
    this.search = props.search
    this.test_item_ids = props.test_item_ids
    this.statuses = props.statuses
    this.page = props.page
  }

  toApi() {
    return {
      search: this.search,
      test_item_ids: this.test_item_ids,
      statuses: this.statuses,
      page: this.page,
    }
  }

  static initialState() {
    return new RequestsFilterPayload({
      search: null,
      test_item_ids: [],
      statuses: ["new_request", "symptoms_added", "conclusion_added", "test_completed"],
      page: null,
    })
  }

}