import { getGetters } from '@/store/getters'
import { getMutations } from '@/store/mutations'
import { getActions } from '@/store/actions'
import { Request as model } from '@/models/api_v1'
import { CmsRequestsService as service } from '@/services/api_v1/cms'
import { RequestsFilterPayload } from '@/payloads/cms'
import { toast } from "@/helpers/error.helper"

// Init
const getInitialState = () => ({
  isInit: null,
  items: null,
  itemsMap: {},
  itemInit: model.initialState(),
  pagination: {},
  payload: {
    RequestsFilter: RequestsFilterPayload.initialState(),
  },
  plot: {},
  pdf: {},
});

// Import
const getters = {
  ...getGetters(),

  getPdf: (state) => (barcode) => {
    return state.pdf[barcode]
  },

  getPlot: (state) => (barcode) => {
    return state.plot[barcode]
  },
}
const mutations = {
  ...getMutations({ model }),

  setPdf(state, { barcode, value }) {
    state.pdf[barcode] = value
  },

  setPlot(state, { barcode, value }) {
    state.plot[barcode] = value
  },

  setPayloadInitial(state, name) {
    const match = {
      'RequestsFilter': RequestsFilterPayload,
    }
    state.payload[name] = match[name].initialState()
  },
}
const actions = {
  ...getActions({ model, service, init: getInitialState() }),

  async sign({ state, commit, dispatch }, id) {
    let output = null
    try {
      output = await service.sign(id)
        .then((response) => {
          return response
        })
    } catch (error) {
      toast({ msg: error })
      output = error
    } finally {
      return output
    }
  },
}

// Export
export default {
  namespaced: true,
  state: getInitialState(),
  getters,
  mutations,
  actions
}
