import _ from "lodash";
import uniqid from "uniqid";

export const getMutations = ({ model, service, init }) => ({

  /**
   * Items
   */

  /** Задать объекты */
  initItems(state, { init, items, idName = 'id' } ) {
    state.items = init.items
    state.itemsMap = init.itemsMap
    items.forEach((item) => (state.itemsMap = { ...state.itemsMap, [item[idName] ? item[idName] : uniqid()]: item }))
    state.items = items
    if ('isInit' in state) state.isInit = true
  },

  /** Добавить объекты */
  addItems(state, items) {
    items.forEach((item) => (state.itemsMap[item.id] = item))
    state.items = Object.values(state.itemsMap)
  },

  /** Добавить объект */
  addItem(state, { item, idName = 'id' }) {
    state.itemsMap = Object.assign({}, state.itemsMap, { [item[idName]]: item })
    state.items = Object.values(state.itemsMap)
  },

  /** Обновить объект */
  updateItem(state, item) {
    state.itemsMap[item.id] = item
    state.itemsMap = { ...state.itemsMap }
    state.items = Object.values(state.itemsMap)
  },

  /** Удалить объект */
  removeItem(state, itemId) {
    delete state.itemsMap[itemId]
    state.items = Object.values(state.itemsMap)
  },

  /** Задать одному объекту значение одного параметра */
  setItemField(state, { id, field, value }) {
    _.set(state.itemsMap[id], field, value);
    _.set(_.find(state.items, { 'id': id }), field, value);
  },

  /** Задать всем объектам значение одного параметра */
  setItemsField(state, { field, value }) {
    for (const [key, val] of Object.entries(state.itemsMap)) {
      val[field] = value
    }
    state.items.map(item => item[field] = value);
  },


  /**
   * ForeignKey
   */

  /** Задать объекты по внешнему ключу */
  initForeignKey(state, { items, id } ) {
    const itemsByIds = items.reduce((map, el) => ({
        ...map,
        [el.id]: el,
    }), {});
    state.itemsMap = {
      ...state.itemsMap,
      [id]: { ...itemsByIds }
    }
  },

  /** Добавить объекты по внешнему ключу */
  addForeignKey(state, { items, id, offset } ) {
    const itemsClear = items.filter((el) => el.id !== Number(offset)).reverse()
    const itemsByIds = itemsClear.reduce((map, el) => ({
        ...map,
        [el.id]: el,
    }), {});
    state.itemsMap = {
      ...state.itemsMap,
      [id]: {
        ...itemsByIds,
        ...state.itemsMap[id],
      }
    }
  },

  /** Добавить объект по внешнему ключу */
  addForeignKeyItem(state, { item, id } ) {
    state.itemsMap[id] = Object.assign({}, state.itemsMap[id], { [item.id]: item });
  },


  /**
   * itemInit
   */

  /** Задать базовый объект */
  setItemInit(state, value) {
    state.itemInit = value
  },

  /** Задать базовому объекту значение одного параметра */
  setItemInitField(state, { field, value }) {
    _.set(state.itemInit, field, value);
  },

  /** Сброс до базового значения */
  setInitialState(state) {
    state.itemInit = model.initialState()
  },


  /**
   * payload
   */
  setPayloadField(state, { name, field, value }) {
    state.payload[name][field] = value
  },


  /**
   * Sets
   */

  /** Задать значение загрузки */
  setLoading(state, value) {
    state.loading = value
  },

  /** Задать объект пагинации */
  setPagination(state, pagination) {
    state.pagination = pagination
  },

  /** Задать поле фильтра */
  setFilterField(state, {field, value}) {
    state.filter[field] = value;
  },

})
