/**
 * @class User
 * @classdesc
 */
export default class User {
  /**
   * @param {string} props.name -
   * @param {string} props.first_name -
   * @param {string} props.last_name -
   * @param {string} props.email -
   * @param {string} props.password -
   * @param {boolean} props.remember -
   * @param {string} props.code -
   * @param {string} props.access_token -
   * @param {string} props.expires_at -
   */
  constructor(props) {
    this.name = props.name
    this.first_name = props.first_name
    this.last_name = props.last_name
    this.email = props.email
    this.password = props.password
    this.remember = props.remember
    this.code = props.code
    this.access_token = props.access_token
    this.expires_at = props.expires_at
  }

  get full_name() {
    return `${this.first_name} ${this.last_name}`
  }

  static fromApi(data) {
    return new User({
      first_name: data?.first_name,
      last_name: data?.last_name,
      email: data?.email,
      password: data?.password,
      remember: data?.remember,
      code: data?.code,
      access_token: data?.access_token,
      expires_at: data?.expires_at,
    })
  }

  toApi() {
    return {
      name: this.name,
      first_name: this.first_name,
      last_name: this.last_name,
      email: this.email,
      password: this.password,
      remember: this.remember,
      code: this.code,
      access_token: this.access_token,
      expires_at: this.expires_at,
    }
  }

  static initialState() {
    return new User({
      name: null,
      first_name: null,
      last_name: null,
      email: null,
      password: null,
      remember: true,
      code: null,
      access_token: null,
      expires_at: null,
    })
  }

}