/**
 * @class ReportTemplate
 * @classdesc
 */
export default class ReportTemplate {
  /**
   * @param {object} props-
   * @param {number} props.id -
   * @param {string} props.title -
   */
  constructor(props) {
    this.id = props.id
    this.title = props.title
  }

  static fromApi(data) {
    return new ReportTemplate({
      id: data.id,
      title: data.title,
    })
  }

  toApi() {
    return {
      id: this.id,
      title: this.title,
    }
  }

  static initialState() {
    return new ReportTemplate({
      id: null,
      title: null,
    })
  }

  /**
   * @param {ApiResult} result
   * @returns {Array.<ReportTemplate>}
   */
  static ArrayFromApiResult(result) {
    let arr = []
    if (result instanceof ReportTemplate && result.status === 200) {
      arr = result.data.data.map(item => ReportTemplate.fromApi(item))
    }
    return arr
  }

}