const apiUrl = '/api/v1'
const resource = 'cms/report'

const getPdf = (id) => {
  return $axios.get(`${apiUrl}/${resource}/${id}`, {
      responseType: 'blob',
      transformResponse: [function (data) {
          let blob = new window.Blob([data], { type: 'application/pdf' })
          return window.URL.createObjectURL(blob)
      }]
  })
}

const download = (id) => {
  return $axios.get(`${apiUrl}/${resource}/download/${id}`, {
      responseType: 'blob',
      transformResponse: [function (data) {
          let blob = new window.Blob([data], { type: 'application/pdf' })
          return window.URL.createObjectURL(blob)
      }]
  })
}

const getConsentForm = (id) => {
  return $axios.get(`${apiUrl}/${resource}/${id}/consent_form.pdf`, {
      responseType: 'blob',
      transformResponse: [function (data) {
          let blob = new window.Blob([data], { type: 'application/pdf' })
          return window.URL.createObjectURL(blob)
      }]
  })
}

const downloadConsentForm = (id) => {
  return $axios.get(`${apiUrl}/${resource}/download/${id}/consent_form.pdf`, {
      responseType: 'blob',
      transformResponse: [function (data) {
          let blob = new window.Blob([data], { type: 'application/pdf' })
          return window.URL.createObjectURL(blob)
      }]
  })
}

const downloadWord = (id) => {
    return $axios.get(`${apiUrl}/${resource}/download/word/${id}`, {
        responseType: 'blob',
        transformResponse: [function (data) {
            let blob = new window.Blob([data], { type: 'application/msword' })
            return window.URL.createObjectURL(blob)
        }]
    })
}

export default {
  getPdf,
  download,
  downloadConsentForm,
  downloadWord,
}
