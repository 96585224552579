const apiUrl = '/api/v1'
const resource = 'patient/auth'
export const URL_LOGOUT = `${apiUrl}/${resource}/logout`

const login = (payload) => {
  return $axios.post(`${apiUrl}/${resource}/login`, payload)
}

const code = (payload) => {
  return $axios.post(`${apiUrl}/${resource}/code`, payload)
}

const resend = () => {
  return $axios.post(`${apiUrl}/${resource}/resend`)
}

const logout = () => {
  return $axios.post(URL_LOGOUT)
}

export default {
  login,
  code,
  resend,
  logout,
}
