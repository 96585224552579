import { createService } from '@/services/service'

const apiUrl = '/api/v1'
const resource = 'cms/chart'

const { fetchById } = createService({ resource, apiUrl })

const getImg = (id) => {
  return $axios.get(`${apiUrl}/${resource}/${id}`, {
      responseType: 'blob',
      transformResponse: [function (data) {
          let blob = new window.Blob([data], { type: 'image/png' })
          return window.URL.createObjectURL(blob)
      }]
  })
}

export default {
  getImg,
  fetchById,
}
