import moment from 'moment'

/**
 * @class Patient
 * @classdesc
 */
export default class Patient {
  /**
   * @param {number} props.id -
   * @param {string} props.dgl -
   * @param {string} props.name -
   * @param {string} props.dob -
   * @param {string} props.sex -
   * @param {string} props.phone -
   * @param {string} props.email -
   * @param {string} props.notify -
   * @param {string} props.access_token -
   * @param {string} props.expires_at -
   */
  constructor(props) {
    this.id = props.id
    this.dgl = props.dgl
    this.name = props.name
    this.dob = props.dob
    this.sex = props.sex
    this.phone = props.phone
    this.email = props.email
    this.notify = props.notify
    this.access_token = props.access_token
    this.expires_at = props.expires_at
  }

  get name_underscore() {
    return this.name?.replace(" ", "_")
  }

  get dob_formatted() {
    return this.dob ? moment(this.dob).format("DD-MM-YYYY") : '-'
  }

  static fromApi(data) {
    return new Patient({
      id: data.id,
      dgl: data.dgl,
      name: data.name,
      dob: data.dob,
      sex: data.sex,
      phone: data.phone,
      email: data.email,
      notify: data.notify,
      access_token: data.access_token,
      expires_at: data.expires_at,
    })
  }

  toApi() {
    return {
      id: this.id,
      dgl: this.dgl,
      name: this.name,
      dob: moment(this.dob).format("YYYY-MM-DD"),
      sex: this.sex,
      phone: this.phone,
      email: this.email,
      notify: this.notify,
      access_token: this.access_token,
      expires_at: this.expires_at,
    }
  }

  static initialState() {
    return new Patient({
      id: null,
      dgl: null,
      name: true,
      dob: null,
      sex: null,
      phone: null,
      email: null,
      notify: null,
      access_token: null,
      expires_at: null,
    })
  }

}