<template>
  <div class="OverlayLayout">
    <slot></slot>
  </div>
</template>
<style lang="scss" scoped>
.OverlayLayout {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: var(--color-primary-background);
    z-index: 10;
    padding: 32px 24px;
  }
</style>