export const getGetters = () => ({

  /**
   * По id
   * itemsMap
   */
  getById: (state) => (id) => {
    return state.itemsMap[id]
  },

  /**
   * Все
   * items
   */
  getAll: (state) => {
    return state.items
  },

  /**
   * Объект с ключами
   * itemsMap
   */
  getAllMap: (state) => {
    return state.itemsMap
  },

  /**
   * Базовое состояние
   * itemInit
   */
  getInit: (state) => {
    return 'itemInit' in state ? state.itemInit : null
  },

  /**
   * Объект Payload
   * payload
   */
  getPayload: (state) => (name) => {
    return state.payload[name]
  },

  /**
   * Объект состояния загрузки
   * loading
   */
  getLoading: (state) => {
    return 'loading' in state ? state.loading : null
  },
  getIsInit: (state) => {
    return 'isInit' in state ? state.isInit : null
  },

  /**
   * Объект пагинации
   * pagination
   */
  getPagination: (state) => {
    return 'pagination' in state ? state.pagination : null
  },

  /**
   * Объект фильтра
   * filter
   */
  getFilter: (state) => {
    return 'filter' in state ? state.filter : null
  },
  getOptions: (state) => {
    return 'Options' in state ? state.options : null
  },

})
