/**
 * @class Agreement
 * @classdesc
 */
export default class Agreement {
  /**
   * @param {object} props-
   * @param {string} props.consent_form_read_at -
   * @param {string} props.contraindications_read_at -
   * @param {string} props.time_requirements_read_at -
   * @param {string} props.test_restrictions_read_at -
   */
  constructor(props) {
    this.consent_form_read_at = props.consent_form_read_at
    this.contraindications_read_at = props.contraindications_read_at
    this.time_requirements_read_at = props.time_requirements_read_at
    this.test_restrictions_read_at = props.test_restrictions_read_at
  }

  static fromApi(data) {
    return new Agreement({
      consent_form_read_at: data.consent_form_read_at,
      contraindications_read_at: data.contraindications_read_at,
      time_requirements_read_at: data.time_requirements_read_at,
      test_restrictions_read_at: data.test_restrictions_read_at,
    })
  }

  toApi() {
    return {
      consent_form_read_at: this.consent_form_read_at,
      contraindications_read_at: this.contraindications_read_at,
      time_requirements_read_at: this.time_requirements_read_at,
      test_restrictions_read_at: this.test_restrictions_read_at,
    }
  }

  static initialState() {
    return new Agreement({
      consent_form_read_at: null,
      contraindications_read_at: null,
      time_requirements_read_at: null,
      test_restrictions_read_at: null,
    })
  }

}