import { createService } from '@/services/service'

const apiUrl = '/api/v1'
const resource = 'cms/patients'

const { updateById, fetchById } = createService({ resource, apiUrl })

export default {
  fetchById,
  updateById,
}
