/**
 * @class ApiResult
 */
export default class ApiResult {

  constructor(props) {
    this.config = props.config
    this.data = props.data
    this.headers = props.headers
    this.request = props.request
    this.status = props.status
    this.statusText = props.statusText
  }

}
