import { ApiResult } from '@/models'
import { toast } from  '@/helpers/error.helper';


export const getActions = ({ model, service, init }) => ({

  /**
   * Первичная загрузка данных
   * service.fetchAll, mutations.initItems
   */
  async fetch({ state, commit, dispatch }, payload = {}) {
    let output = null
    try {
      output = await service.fetchAll(payload)
        .then((response) => {
          let result = new ApiResult(response),
              items = model.ArrayFromApiResult(result)
          if ('pagination' in state) commit('setPagination', result.data.meta)
          commit('initItems', { init, items })
          return response
        })
    } catch (error) {
      toast({ msg: error })
      output = error
    } finally {
      return output
    }
  },

  /**
   * Подгрузка данных
   * service.fetchAll, mutations.addItems
   */
  async fetchAdd({ state, commit, dispatch }, payload = {}) {
    let output = null
    try {
      output = await service.fetchAll(payload)
        .then((response) => {
          let result = new ApiResult(response),
              items = model.ArrayFromApiResult(result)
          if ('pagination' in state) {
            commit('setPagination', result.data.meta)
          }
          commit('addItems', items)
          return response
        })
    } catch (error) {
      toast({ msg: error })
      output = error
    } finally {
      return output
    }
  },

  /**
   * Подгрузка данных по id
   * service.fetchById, mutations.addItem
   */
  async fetchById({ state, commit, dispatch }, id) {
    let output = null
    try {
      output = await service.fetchById(id)
        .then((response) => {
          let result = new ApiResult(response),
              item = model.fromApi('data' in result.data ? result.data.data : result.data)
          if ('pagination' in state) {
            commit('setPagination', result.data.meta)
          }
          commit('addItem', { item })
          return response
        })
    } catch (error) {
      toast({ msg: error })
      output = error
    } finally {
      return output
    }
  },

  /**
   * service.fetchById, mutations.addItem
   */
  async fetchInit({ state, commit, dispatch }, id = null) {
    let output = null
    try {
      output = await service[id ? "fetchById" : "fetchAll"](id)
        .then((response) => {
          let result = new ApiResult(response),
              item = model.fromApi('data' in result.data ? result.data.data : result.data)
          commit('setItemInit', item)
          return response
        })
    } catch (error) {
      toast({ msg: error })
      output = error
    } finally {
      return output
    }
  },

  /**
   * Изменение данных по id
   * service.updateById, mutations.updateItem
   */
  async updateById({ state, commit, dispatch }, { id, payload, config = {}}) {
    let output = null
    try {
      output = await service.updateById(id, payload, config)
        .then((response) => {
          let result = new ApiResult(response),
              item = model.fromApi('data' in result.data ? result.data.data : result.data)
          commit('updateItem', item)
          return response
        })
    } catch (error) {
      toast({ msg: error })
      output = error
    } finally {
      return output
    }
  },

  /**
   * Удаление по id
   * service.deleteById, mutations.removeItem
   */
  async deleteById({ state, commit, dispatch }, { id }) {
    let output = null
    try {
      output = await service.deleteById(id)
        .then((response) => {
          let result = new ApiResult(response)
          commit('removeItem', id)
          return response
        })
    } catch (error) {
      toast({ msg: error })
      output = error
    } finally {
      return output
    }
  },

  async sendCancel({ state, commit, dispatch }, id ) {
    let output = null
    try {
      output = await service.sendCancel(id)
        .then((response) => {
          return response
        })
    } catch (error) {
      toast({ msg: error })
      output = error
    } finally {
      return output
    }
  },

  /**
   * Создать
   * service.create, mutations.addItem
   */
  async create({ state, commit, dispatch }, { payload }) {
    let output = null
    try {
      output = await service.create(payload)
        .then((response) => {
          let result = new ApiResult(response),
              item = model.fromApi('data' in result.data ? result.data.data : result.data)
          commit('addItem', { item })
          return response
        })
    } catch (error) {
      toast({ msg: error })
      output = error
    } finally {
      return output
    }
  },

})