import { ApiResult } from "@/models"
import { isoDate } from  '@/helpers/date.helper'

/**
 * @class Sample
 * @classdesc
 */
export default class Sample {
  /**
   * @param {object} props-
   * @param {number} props.id -
   * @param {string} props.barcode -
   * @param {number} props.number -
   * @param {number} props.time -
   * @param {boolean | null} props.bloating -
   * @param {boolean | null} props.abdominal_pain -
   * @param {boolean | null} props.nausea -
   * @param {boolean | null} props.hydrogen -
   * @param {boolean | null} props.methane -
   * @param {boolean | null} props.carbon_dioxide -
   * @param {string} props.sample_added_at -
   */
  constructor(props) {
    this.id = props.id
    this.barcode = props.barcode
    this.number = props.number
    this.time = props.time
    this.bloating = props.bloating
    this.abdominal_pain = props.abdominal_pain
    this.nausea = props.nausea
    this.hydrogen = props.hydrogen
    this.methane = props.methane
    this.carbon_dioxide = props.carbon_dioxide
    this.sample_added_at = props.sample_added_at
  }

  get sample_added_at_iso() {
    return isoDate(this.sample_added_at)
  }

  get isEmpty() {
    return this.sample_added_at === null
  }

  static fromApi(data) {
    return new Sample({
      id: data.id,
      barcode: data.barcode,
      number: data.number,
      time: data.time,
      bloating: data.bloating || 0,
      abdominal_pain: data.abdominal_pain || 0,
      nausea: data.nausea || 0,
      hydrogen: data.hydrogen,
      methane: data.methane,
      carbon_dioxide: data.carbon_dioxide?.toFixed(2),
      sample_added_at: data.sample_added_at,
    })
  }

  toApi() {
    return {
      id: this.id,
      barcode: this.barcode,
      number: this.number,
      time: this.time,
      bloating: this.bloating,
      abdominal_pain: this.abdominal_pain,
      nausea: this.nausea,
      hydrogen: this.hydrogen,
      methane: this.methane,
      carbon_dioxide: this.carbon_dioxide,
      sample_added_at: this.sample_added_at,
    }
  }

  static initialState() {
    return new Sample({
      id: null,
      barcode: null,
      number: null,
      time: null,
      bloating: 0,
      abdominal_pain: 0,
      nausea: 0,
      hydrogen: null,
      methane: null,
      carbon_dioxide: null,
      sample_added_at: null,
    })
  }

  /**
   * @param {ApiResult} result
   * @returns {Array.<Sample>}
   */
  static ArrayFromApiResult(result) {
    let arr = []
    if (result instanceof ApiResult && result.status === 200) {
      arr = result.data.data.map(item => Sample.fromApi(item))
    }
    return arr
  }

}