import { createService } from '@/services/service'

const apiUrl = '/api/v1'
const resource = 'cms/initial'

const { fetchAll } = createService({ resource, apiUrl })

export default {
  fetchAll,
}
