import { getGetters } from '@/store/getters'
import { getMutations } from '@/store/mutations'
import { Patient as model, Agreement } from '@/models/api_v1'
import { PatientAuthService as service, PatientPatientService, PatientAgreementService } from '@/services/api_v1/patient'
import { AuthPayload } from '@/payloads/patient'
import { toast } from '@/helpers/error.helper'
import _ from "lodash"

// Init
const getInitialState = () => ({
  patient: localStorage.getItem('patient') ? JSON.parse(localStorage.getItem('patient')) : null,
  test: localStorage.getItem('test') ? JSON.parse(localStorage.getItem('test')) : null,
  agreement: localStorage.getItem('agreement') ? JSON.parse(localStorage.getItem('agreement')) : null,
  itemInit: model.initialState(),
  payload: {
    Auth: AuthPayload.initialState(),
  },
});

// Import
const { getInit, getPayload } = getGetters()
const { setItemInitField, setInitialState, setItemInit, setPayloadField } = getMutations({ model })

const getters = {
  getInit,
  getPayload,

  getTest: (state) => {
    return state.test
  },

  getAgreement: (state) => {
    return state.agreement ? Agreement.fromApi(state.agreement) : null
  },

  getPatient: (state) => {
    return state.patient ? model.fromApi(state.patient) : null
  },
}
const mutations = {
  setItemInitField,
  setInitialState,
  setItemInit,
  setPayloadField,

  setTest(state, value) {
    localStorage.setItem('test', JSON.stringify(value))
    state.test = value
  },
  resetTest(state) {
    localStorage.removeItem('test')
    state.test = null
  },

  setAgreement(state, value) {
    localStorage.setItem('agreement', JSON.stringify(value))
    state.agreement = value
  },
  resetAgreement(state) {
    localStorage.removeItem('agreement')
    state.agreement = null
  },
  setAgreementField(state, { field, value }) {
    _.set(state.agreement, field, value)
  },

  setPatient(state, value) {
    localStorage.setItem('patient', JSON.stringify(value))
    state.patient = _.cloneDeep(value)
  },
  resetPatient(state) {
    localStorage.removeItem('patient')
    state.patient = null
  },
  setPatientField(state, { field, value }) {
    _.set(state.patient, field, value)
  },
}
const actions = {

  async login({ state, commit, dispatch }, { payload }) {
    let output = null

    try {
      output = await service.login(payload)
    } catch (error) {
      toast({ msg: error })
      output = error
    } finally {
      return output
    }
  },

  async code({ state, commit, dispatch }, { payload }) {
    let output = null

    try {
      output = await service.code(payload)
    } catch (error) {
      toast({ msg: error })
      output = error
    } finally {
      return output
    }
  },

  async resend({ state, commit, dispatch }) {
    let output = null

    try {
      output = await service.resend()
    } catch (error) {
      toast({ msg: error })
      output = error
    } finally {
      return output
    }
  },

  async logout({ state, commit, dispatch }) {
    let output = null

    try {
      output = await service.logout()
    } catch (error) {
      toast({ msg: error })
      output = error
    } finally {
      return output
    }
  },

  async update({ state, commit, dispatch }, { payload }) {
    let output = null

    try {
      output = await PatientPatientService.update(payload)
    } catch (error) {
      toast({ msg: error })
      output = error
    } finally {
      return output
    }
  },

  async sendAgreement({ state, commit, dispatch }, { payload }) {
    let output = null

    try {
      output = await PatientAgreementService.update(payload)
    } catch (error) {
      toast({ msg: error })
      output = error
    } finally {
      return output
    }
  },

}

// Export
export default {
  namespaced: true,
  state: getInitialState(),
  getters,
  mutations,
  actions,
}
