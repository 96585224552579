import { createService } from '@/services/service'

const apiUrl = '/api/v1'
const resource = 'cms/samples'

const { fetchById, updateById } = createService({ resource, apiUrl })

export default {
  fetchById,
  updateById,
}
