const apiUrl = '/api/v1'
const resource = 'patient'

const get = (payload) => {
  return $axios.get(`${apiUrl}/${resource}/show`, payload)
}

const update = (payload) => {
  return $axios.put(`${apiUrl}/${resource}/update`, payload)
}

export default {
  get,
  update,
}
