<template>
  <div class="App">
    <OverlayLayout v-if="allowBlock && isMobile" class="mobileBlocker">
      <div class="mobileBlocker__block">
        <LogoTitle />
        <p class="mobileBlocker_big">The administration portal doesn’t work on mobile</p>
        <p class="mobileBlocker_small">Please try on your desktop</p>
      </div>
    </OverlayLayout>
    <router-view />
  </div>
</template>

<script setup>
import LogoTitle from '@/v2/shared/ui/elements/LogoTitle.vue';
import OverlayLayout from '@/v2/shared/ui/layouts/OverlayLayout.vue';
import { onBeforeMount, ref } from 'vue';

const windowWidth = ref(window.innerWidth);
const isMobile = ref(false)

const allowBlock = window.location.host.split('.')[0].includes('cms')

window.addEventListener('resize', () => {
  windowWidth.value = window.innerWidth
});
onBeforeMount(()=>{
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ];
  isMobile.value = toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
})
</script>
  
<style lang="scss" scoped>
  .mobileBlocker{
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    &__block{
      width: 430px;
    }
    &_big{
      font-weight: 700;
      font-size: 32px;
      line-height: 33px;
      margin-top: 24px;
      margin-bottom: 12px;
    }
    &_small{
      font-weight: 600;
      font-size: 22px;
      line-height: 24px;
    }
  }
  </style>
  