import { ApiResult } from '@/models'
import { User, TestItem, Patient, Sample, Agreement } from '@/models/api_v1'
import requestStatuses from '@/vocabulary/requestStatuses.data.json'
import moment from 'moment'
import {isoDate} from "@/helpers/date.helper";

/**
 * @class Request
 * @classdesc
 */
export default class Request {
  /**
   * @param {object} props
   * @param {string} props.email -
   * @param {number} props.id -
   * @param {object} props.test_item -
   * @param {string} props.dgl -
   * @param {string} props.barcode -
   * @param {string} props.status -
   * @param {object} props.patient -
   * @param {object} props.agreement -
   * @param {string} props.test_started_at -
   * @param {string} props.test_taken_at -
   * @param {string} props.gc_analysis_at -
   * @param {string} props.referring_physician -
   * @param {string} props.patient_number -
   * @param {object} props.conclusion_added_user -
   * @param {number} props.conclusion_template_id -
   * @param {} props.conclusion -
   * @param {number} props.report_template_id -
   * @param {string} props.report_completed_at -
   * @param {string} props.clinical_comments -
   * @param {string} props.result_united_kingdom -
   * @param {string} props.result_north_american -
   * @param {string} props.result_methane -
   * @param {string} props.created_at -
   * @param {string} props.updated_at -
   */
  constructor(props) {
    this.id = props.id
    this.test_item = new TestItem(props.test_item || {})
    this.barcode = props.barcode
    this.status = props.status
    this.patient = new Patient(props.patient || {})
    this.agreement = new Agreement(props.agreement || {})
    this.test_started_at = props.test_started_at
    this.test_taken_at = props.test_taken_at
    this.gc_analysis_at = props.gc_analysis_at
    this.referring_physician = props.referring_physician
    this.patient_number = props.patient_number
    this.conclusion_added_user = new User(props.conclusion_added_user || {})
    this.conclusion_template_id = props.conclusion_template_id
    this.conclusion = props.conclusion
    this.report_template_id = props.report_template_id
    this.report_completed_at = props.report_completed_at
    this.clinical_comments = props.clinical_comments
    this.patient_comments = props.patient_comments
    this.result_united_kingdom = props.result_united_kingdom
    this.result_north_american = props.result_north_american
    this.result_methane = props.result_methane
    this.samples = props.samples?.map(item => new Sample(item || {}))
    this.created_at = props.created_at
    this.updated_at = props.updated_at
  }

  get test_started_at_iso() {
    return isoDate(this.test_started_at)
  }

  get status_formatted() {
    return requestStatuses[this.status]
  }

  get test_taken_formatted() {
    return this.test_taken_at ? moment(this.test_taken_at).format("DD-MM-YYYY") : null
  }

  get gc_analysis_formatted() {
    return this.gc_analysis_at ? moment(this.gc_analysis_at).format("DD-MM-YYYY") : null
  }

  get report_completed_formatted() {
    return this.report_completed_at ? moment(this.report_completed_at).format("DD-MM-YYYY") : null
  }

  static fromApi(data) {
    return new Request({
      id: data.id,
      test_item: TestItem.fromApi(data.test_item || {}),
      barcode: data.barcode,
      status: data.status,
      patient: Patient.fromApi(data.patient || {}),
      agreement: Agreement.fromApi(data.agreement || {}),
      test_started_at: data.test_started_at,
      test_taken_at: data.test_taken_at,
      gc_analysis_at: data.gc_analysis_at,
      referring_physician: data.referring_physician,
      patient_number: data.patient_number,
      conclusion_added_user: User.fromApi(data.conclusion_added_user || {}),
      conclusion_template_id: data.conclusion_template_id,
      conclusion: data.conclusion,
      report_template_id: data.report_template_id,
      report_completed_at: data.report_completed_at,
      clinical_comments: data.clinical_comments,
      patient_comments: data.patient_comments,
      result_united_kingdom: data.result_united_kingdom,
      result_north_american: data.result_north_american,
      result_methane: data.result_methane,
      samples: data.samples?.map(item => Sample.fromApi(item || {})),
      created_at: data.created_at,
      updated_at: data.updated_at,
    })
  }

  toApi() {
    return {
      id: this.id,
      test_item: this.test_item.toApi(),
      barcode: this.barcode,
      status: this.status,
      patient: this.patient.toApi(),
      agreement: this.agreement.toApi(),
      test_started_at: this.test_started_at,
      test_taken_at: moment(this.test_taken_at).format("YYYY-MM-DD"),
      gc_analysis_at: this.gc_analysis_at,
      referring_physician: this.referring_physician,
      patient_number: this.patient_number,
      conclusion_added_user: this.conclusion_added_user.toApi(),
      conclusion_template_id: this.conclusion_template_id,
      conclusion: this.conclusion,
      report_template_id: this.report_template_id,
      report_completed_at: this.report_completed_at,
      clinical_comments: this.clinical_comments,
      patient_comments: this.patient_comments,
      result_united_kingdom: this.result_united_kingdom,
      result_north_american: this.result_north_american,
      result_methane: this.result_methane,
      samples: this.samples?.map(item => item.toApi()),
      created_at: this.created_at,
      updated_at: this.updated_at,

    }
  }

  static initialState() {
    return new Request({
      id: null,
      test_item: null,
      barcode: null,
      status: null,
      patient: null,
      agreement: null,
      test_started_at: null,
      test_taken_at: null,
      gc_analysis_at: null,
      referring_physician: null,
      patient_number: null,
      conclusion_added_user: null,
      conclusion_template_id: null,
      conclusion: null,
      report_template_id: null,
      report_completed_at: null,
      clinical_comments: null,
      patient_comments: null,
      result_united_kingdom: null,
      result_north_american: null,
      result_methane: null,
      samples: null,
      created_at: null,
      updated_at: null,
    })
  }

  /**
   * @param {ApiResult} result
   * @returns {Array.<Request>}
   */
  static ArrayFromApiResult(result) {
    let arr = []
    if (result instanceof ApiResult && result.status === 200) {
      arr = result.data.data.map(item => Request.fromApi(item))
    }
    return arr
  }

}