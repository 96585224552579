import { Sample } from "@/models/api_v1"

/**
 * @class RequestSamples (RequestModelSamples)
 * @classdesc
 */
export default class RequestSamples {
  /**
   * @param {object} props-
   * @param {string} props.barcode -
   * @param {string} props.patient_comments -
   * @param {array} props.samples -
   */
  constructor(props) {
    this.barcode = props.barcode
    this.patient_comments = props.patient_comments
    this.samples = props.samples?.map(item => new Sample(item || {}))
  }

  static fromApi(data) {
    return new RequestSamples({
      barcode: data.barcode,
      patient_comments: data.patient_comments,
      samples: data.samples?.map(item => Sample.fromApi(item || {})),
    })
  }

  toApi() {
    return {
      barcode: this.barcode,
      patient_comments: this.patient_comments,
      samples: this.samples.map(item => item.toApi()),
    }
  }

  static initialState() {
    return new RequestSamples({
      barcode: null,
      patient_comments: null,
      samples: null,
    })
  }

}