//** 1.3.0 DEPRICATED */
// const apiUrl = '/api/v1'
// const resource = 'cms/auth'
//** 1.3.0 DEPRICATED */
const apiUrl = ''
const resource = 'auth'
export const URL_LOGOUT = `${apiUrl}/${resource}/logout`

const login = (payload) => {
  return $axios.post(`${apiUrl}/${resource}/login`, payload)
}

const code = (payload) => {
  return $axios.post(`${apiUrl}/${resource}/code`, payload)
}

const resend = (payload) => {
  return $axios.post(`${apiUrl}/${resource}/resend`, payload)
}

const logout = () => {
  return $axios.post(URL_LOGOUT)
}

const profile = () => {
  return $axios.get('/auth/profile');
}

const forgotPassword = (payload) => {
  return $axios.post('/auth/forgot-password', payload);
}

const newPassword = (payload) => {
  return $axios.post('/auth/new-password', payload);
}

export default {
  login,
  code,
  resend,
  logout,
  profile,
  forgotPassword,
  newPassword
}
