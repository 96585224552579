/**
 * @class ConclusionTemplate
 * @classdesc
 */
export default class ConclusionTemplate {
  /**
   * @param {object} props-
   * @param {number} props.id -
   * @param {number} props.test_item_id -
   * @param {string} props.title -
   * @param {object} props.content -
   */
  constructor(props) {
    this.id = props.id
    this.test_item_id = props.test_item_id
    this.title = props.title
    this.content = {
      text: props.content?.text,
    }
  }

  static fromApi(data) {
    return new ConclusionTemplate({
      id: data.id,
      test_item_id: data.test_item_id,
      title: data.title,
      content: {
        text: data.content?.text,
      }
    })
  }

  toApi() {
    return {
      id: this.id,
      title: this.title,
      test_item_id: this.test_item_id,
      content: this.content, // object
    }
  }

  static initialState() {
    return new ConclusionTemplate({
      id: null,
      title: null,
      test_item_id: null,
      content: {
        text: null,
      },
    })
  }

  /**
   * @param {ApiResult} result
   * @returns {Array.<ConclusionTemplate>}
   */
  static ArrayFromApiResult(result) {
    let arr = []
    if (result instanceof ConclusionTemplate && result.status === 200) {
      arr = result.data.data.map(item => ConclusionTemplate.fromApi(item))
    }
    return arr
  }

}