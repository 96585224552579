import { createService } from '@/services/service'

const apiUrl = '/api/v1'
const resource = 'patient/samples'

const { fetchAll } = createService({ resource, apiUrl })

const update = (payload) => {
  return $axios.put(`${apiUrl}/${resource}`, payload)
}

export default {
  fetchAll,
  update,
}
