import { TestItem, ReportTemplate, ConclusionTemplate } from "@/models/api_v1"

/**
 * @class Initial
 * @classdesc
 */
export default class Initial {
  /**
   * @param {object} props-
   * @param {array.<TestItem>} props.test_items -
   * @param {array.<ReportTemplate>} props.report_templates -
   * @param {array.<ConclusionTemplate>} props.conclusion_templates -
   */
  constructor(props) {
    this.test_items = props.test_items?.map(item => new TestItem(item || {}))
    this.report_templates = props.report_templates?.map(item => new ReportTemplate(item || {}))
    this.conclusion_templates = props.conclusion_templates?.map(item => new ConclusionTemplate(item || {}))
  }

  static fromApi(data) {
    return new Initial({
      test_items: data.test_items?.map(item => TestItem.fromApi(item || {})),
      report_templates: data.report_templates?.map(item => ReportTemplate.fromApi(item || {})),
      conclusion_templates: data.conclusion_templates?.map(item => ConclusionTemplate.fromApi(item || {})),
    })
  }

  toApi() {
    return {
      test_items: this.test_items.map(item => item.toApi()),
      report_templates: this.report_templates.map(item => item.toApi()),
      conclusion_templates: this.conclusion_templates.map(item => item.toApi()),
    }
  }

  static initialState() {
    return new Initial({
      test_items: null,
      report_templates: null,
      conclusion_templates: null,
    })
  }

}