import { createService } from '@/services/service'

const apiUrl = '/api/v1'
const resource = 'cms/conclusion'

const { updateById } = createService({ resource, apiUrl })

export default {
  updateById,
}
