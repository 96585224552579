import { createService } from '@/services/service'

const apiUrl = '/api/v1'
const resource = 'cms/requests'

const { fetchAll, fetchById, updateById } = createService({ resource, apiUrl })

const sign = (id) => {
  return $axios.post(`${apiUrl}/${resource}/${id}/sign`)
}

export default {
  fetchAll,
  fetchById,
  updateById,
  sign,
}
