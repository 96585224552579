export const createService = ({ resource, apiUrl }) => ({
  resource,

  fetchAll(params) {
    return $axios.get(`${apiUrl}/${resource}`, { params }) // Get list
  },

  fetchById(id, params) {
    return $axios.get(`${apiUrl}/${resource}/${id}`, { params }) // Show
  },

  create(payload, config = {}) {
    return $axios.post(`${apiUrl}/${resource}/create`, payload, config) // Create
  },

  search(payload, config = {}) {
    return $axios.post(`${apiUrl}/${resource}/search`, payload, config)
  },

  updateById(id, payload, config = {}) {
    return $axios.put(`${apiUrl}/${resource}/${id}`, payload, config) // Update
  },

  deleteAll(params) {
    return $axios.delete(`${apiUrl}/${resource}`, { params })
  },

  deleteById(id) {
    return $axios.delete(`${apiUrl}/${resource}/${id}`) // Delete
  },

  sendCancel(id) {
    return $axios.get(`${apiUrl}/${resource}/cancel/${id}`)
  },
})
