/**
 * @class AuthPayload
 * @classdesc
 */
export default class AuthPayload {
  /**
   * @param {object} props -
   * @param {string} props.phone -
   * @param {string} props.barcode -
   * @param {number} props.test_item_id -
   * @param {number} props.code -
   */
  constructor(props) {
    this.phone = props.phone
    this.barcode = props.barcode
    this.test_item_id = props.test_item_id
    this.code = props.code
  }

  toApi() {
    return {
      phone: this.phone ? `+${this.phone.replace(/[\D]/g, '')}` : null,
      barcode: this.barcode,
      test_item_id: this.test_item_id,
      code: this.code,
    }
  }

  static initialState() {
    return new AuthPayload({
      phone: null,
      barcode: null,
      test_item_id: null,
      code: null,
    })
  }
}
