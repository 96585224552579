import {ApiResult} from "@/models";

/**
 * @class TestItem
 * @classdesc
 */
export default class TestItem {
  /**
   * @param {object} props-
   * @param {number} props.id -
   * @param {string} props.title -
   * @param {string} props.content -
   * @param {string} props.image -
   */
  constructor(props) {
    this.id = props.id
    this.title = props.title
    this.content = props.content
    this.image = props.image
    // sample_count
    // sample_default_time
  }

  static fromApi(data) {
    return new TestItem({
      id: data.id,
      title: data.title,
      content: data.content,
      image: data.image,
    })
  }

  toApi() {
    return {
      id: this.id,
      title: this.title,
      content: this.content,
      image: this.image,
    }
  }

  static initialState() {
    return new TestItem({
      id: null,
      title: null,
      content: null,
      image: null,
    })
  }

  /**
   * @param {ApiResult} result
   * @returns {Array.<TestItem>}
   */
  static ArrayFromApiResult(result) {
    let arr = []
    if (result instanceof TestItem && result.status === 200) {
      arr = result.data.data.map(item => TestItem.fromApi(item))
    }
    return arr
  }

}