import { getGetters } from '@/store/getters'
import { getMutations } from '@/store/mutations'
import { getActions } from '@/store/actions'
import { RequestSamples as model } from '@/models/api_v1'
import { CmsSamplesService as service } from '@/services/api_v1/cms'

// Init
const getInitialState = () => ({
  isInit: null,
  items: null,
  itemsMap: {},
  itemInit: model.initialState(),
});

// Import
const getters = getGetters()
const mutations = getMutations({ model })
const actions = getActions({ model, service, init: getInitialState() })

// Export
export default {
  namespaced: true,
  state: getInitialState(),
  getters,
  mutations,
  actions
}
