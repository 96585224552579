const prefix = ''
// import LoginPage from '@/components/patient/auth/LoginPage'
// import ConfirmPage from '@/components/patient/confirm/ConfirmPage'
// import SamplesPage from '@/components/patient/samples/SamplesPage'
// import SamplePage from '@/components/patient/sample/SamplePage'
// import CommentPage from '@/components/patient/sample/CommentPage'
// import SamplesManualsPage from '@/components/patient/samples/SamplesManualsPage'

const LoginPage = () => import('@/components/patient/auth/LoginPage')
const ConfirmPage = () => import('@/components/patient/confirm/ConfirmPage')
const SamplesPage = () => import('@/components/patient/samples/SamplesPage')
const SamplePage = () => import('@/components/patient/sample/SamplePage')
const CommentPage = () => import('@/components/patient/sample/CommentPage')
const SamplesManualsPage = () => import('@/components/patient/samples/SamplesManualsPage')

export const patientRoutes = window.location.host.split('.')[0] === 'patient-portal' || window.location.host.split('.')[0] === 'fgc-patient-portal' ?
    [
        // auth
        {
            path: `${prefix}/auth`,
            name: 'patient.auth',
            component: LoginPage,
        },
        {
            path: `${prefix}/auth/code`,
            name: 'patient.auth.code',
            component: LoginPage,
        },
        {
            path: `${prefix}/auth/user`,
            name: 'patient.auth.user',
            component: LoginPage,
        },
        {
            path: `${prefix}/auth/notify`,
            name: 'patient.auth.notify',
            component: LoginPage,
        },
        {
            path: `${prefix}`,
            redirect: {name: 'patient.samples'},
        },

        // confirm
        {
            path: `${prefix}/confirm/consent`,
            name: 'patient.confirm.consent',
            component: ConfirmPage,
        },
        {
            path: `${prefix}/confirm/contras`,
            name: 'patient.confirm.contras',
            component: ConfirmPage,
        },
        {
            path: `${prefix}/confirm/time`,
            name: 'patient.confirm.time',
            component: ConfirmPage,
        },
        {
            path: `${prefix}/confirm/test`,
            name: 'patient.confirm.test',
            component: ConfirmPage,
        },

        // samples
        {
            path: `${prefix}/samples`,
            name: 'patient.samples', // home
            component: SamplesPage,
        },
        {
            path: `${prefix}/samples/:sample`,
            name: 'patient.sample',
            component: SamplePage,
        },
        {
            path: `${prefix}/samples/comment`,
            name: 'patient.samples.comment',
            component: CommentPage,
        },
        {
            path: `${prefix}/samples/baseline`,
            name: 'patient.samples.baseline',
            component: SamplesManualsPage,
        },
        {
            path: `${prefix}/samples/collect`,
            name: 'patient.samples.collect',
            component: SamplesManualsPage,
        },
        {
            path: `${prefix}/samples/label`,
            name: 'patient.samples.label',
            component: SamplesManualsPage,
        },
        {
            path: `${prefix}/samples/substrate`,
            name: 'patient.samples.substrate',
            component: SamplesManualsPage,
        },
        {
            path: `${prefix}/samples/return`,
            name: 'patient.samples.return',
            component: SamplesManualsPage,
        },
    ] : []
